import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

function NotFoundPage() {
  return (
    <Layout lightHeader>
      <SEO title="404: Not found" />
      <section className="black-bg not-found-main flex align-center justify-center text-center">
        <div className="container">
          <h1>404</h1>
          <Link to="/">
            Go to Homepage{' '}
            <svg width="18" height="12" fill="none" viewBox="0 0 18 12">
              <path
                fill="#BEB281"
                d="m12.039.343-1.411 1.418 3.27 3.255-13.605.013.002 2 13.568-.013-3.215 3.23 1.417 1.41 5.644-5.67-5.67-5.643Z"
              />
            </svg>
          </Link>
        </div>
      </section>
    </Layout>
  );
}

export default NotFoundPage;
